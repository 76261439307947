@import 'scss/functions';
@import 'scss/mixins';
@import 'scss/typography';
@import 'scss/media-queries';

$btn-transparent: rgba(255, 255, 255, 0.15);

// header button variant mappings
$header-button-variants: (
  // standard
  standard:
    (
      default: (
        background: var(--black-interaction-standard),
        color: var(--neutral-6),
      ),
      hover: (
        background: var(--black-interaction-hover),
        color: var(--neutral-8),
        border-radius: var(--border-radius-24),
      ),
      active: (
        background: var(--black-interaction-hover),
        color: var(--black-interaction-text-pressed-color),
        border-radius: var(--border-radius-24),
      ),
      disabled: (),
      keyboard-focus: (
        background: var(--black-interaction-hover),
        color: var(--neutral-8),
        outline: 2px var(--neutral-9) solid,
        outline-offset: 2px,
      ),
    ),
  // outline
  outline:
    (
      default: (
        border: 1px var(--neutral-4) solid,
        background: var(--black-interaction-standard),
        color: var(--neutral-6),
      ),
      hover: (
        border: 1px var(--neutral-8) solid,
        background: var(--black-interaction-hover),
        color: var(--neutral-8),
      ),
      active: (
        border: 1px var(--neutral-6) solid,
        background: var(--black-interaction-hover),
        color: var(--neutral-4),
      ),
      disabled: (),
      keyboard-focus: (
        border: 1px var(--neutral-6) solid,
        background: var(--black-interaction-hover),
        color: var(--neutral-8),
        outline: 2px var(--neutral-4) solid,
        outline-offset: 2px,
      ),
    ),
  outline-transparent: (
    default: (
      background: transparent,
      border: 1px solid var(--neutral-6),
      color: var(--neutral-6),
      border-radius: var(--border-radius-24),
    ),
    hover: (
      background: $btn-transparent,
    ),
    active: (
      background: $btn-transparent,
    ),
    disabled: (),
    keyboard-focus: (
      background: $btn-transparent,
      outline: 2px solid var(--neutral-4),
      outline-offset: 2px,
    ),
  ),
  transparent: (
    default: (
      background: transparent,
      color: var(--neutral-6),
      border-radius: var(--border-radius-24),
    ),
    hover: (
      background: $btn-transparent,
    ),
    active: (
      background: $btn-transparent,
    ),
    disabled: (),
    keyboard-focus: (
      background: $btn-transparent,
      outline: 2px solid var(--neutral-4),
      outline-offset: 2px,
    ),
  ),
  // avatar
  avatar:
    (
      default: (
        background: var(--primary),
        color: var(--text-color),
        height: 32px,
        width: 32px,
        margin: var(--space-2),
      ),
      hover: (
        outline: 8px var(--black-interaction-hover) solid,
        background: var(--primary),
        color: var(--black-interaction-hover),
      ),
      active: (
        outline: 8px var(--black-interaction-hover) solid,
        background: var(--primary),
        color: var(--text-color),
      ),
      disabled: (),
      keyboard-focus: (
        background: var(--primary),
        color: var(--black-interaction-hover),
        outline: 2px var(--neutral-4) solid,
        outline-offset: 2px,
      ),
    ),
  avatar-transparent: (
    default: (
      background: var(--primary),
      color: var(--text-color),
      height: 32px,
      width: 32px,
      margin: var(--space-2),
    ),
    hover: (
      outline: 8px $btn-transparent solid,
      background: var(--primary),
      color: var(--black-interaction-hover),
    ),
    active: (
      background: var(--primary),
      color: var(--text-color),
    ),
    disabled: (),
    keyboard-focus: (
      background: var(--primary),
      color: var(--black-interaction-hover),
      outline: 2px $btn-transparent solid,
      outline-offset: 2px,
    ),
  )
);

@keyframes bouncing-loader {
  to {
    opacity: 1;
    transform: translate3d(0, -6px, 0);
  }
}

.btn {
  --btn-height: 32px;

  margin: var(--space-1);
  height: var(--btn-height);

  &::after {
    content: '';
    box-shadow: unset;
    margin: 0;
  }

  @each $variant, $content in $header-button-variants {
    &.btn-#{$variant} {
      @include spread-props(map-get-strict($content, 'default'));

      &:hover:not(:disabled):not(:active) {
        @include spread-props(map-get-strict($content, 'hover'));
        transform: unset;
      }

      // Reset to default state on mobile device after tap
      @media (-moz-touch-enabled: 1), (pointer: coarse) {
        &:hover:not(:disabled):not(:active) {
          @include spread-props(map-get-strict($content, 'default'));
        }
      }

      &:active {
        @include spread-props(map-get-strict($content, 'active'));
      }
      &:disabled {
        cursor: not-allowed;
        @include spread-props(map-get-strict($content, 'disabled'));
      }
      &:focus-visible {
        @include spread-props(map-get-strict($content, 'keyboard-focus'));
      }
    }
  }

  &.btn-small {
    @extend .text-sm;
    --btn-v-padding: var(--space-2);
    --btn-h-padding: var(--space-3);
    --btn-min-width: unset;
  }

  &.btn-medium {
    --btn-h-padding: var(--space-3);
    --btn-height: 32px;
    --btn-min-width: unset;

    @extend .text-sm;

    &.btn-circular {
      --btn-h-padding: calc(var(--space-2) - 1px);
    }
  }

  @include until-wide-screen {
    .responsive-label {
      display: none;
    }
  }
}
