@import 'scss/functions';
@import 'scss/mixins';
@import 'scss/typography';

$no-shadow: none !important;

// button variant mappings
$button-variants: (
  // primary
  primary:
    (
      default: (
        background: var(--black-interaction-standard),
        color: var(--black-interaction-text-hover-color),
        // border-radius: var(--border-radius-md),
      ),
      hover: (
        background: var(--black-interaction-hover),
        color: var(--black-interaction-text-hover-color),
      ),
      active: (
        background: var(--black-interaction-pressed),
        color: var(--black-interaction-text-pressed-color),
        --btn-box-shadow: var(--black-interaction-shadow-pressed),
      ),
      disabled: (
        background: var(--black-interaction-disabled),
        color: var(--black-interaction-text-disabled-color),
        --btn-box-shadow: unset,
      ),
      keyboard-focus: (
        background: var(--black-interaction-standard),
        color: var(--black-interaction-text-hover-color),
        --btn-box-shadow: var(--black-interaction-shadow-keyboard-focus),
      ),
      loading: (
        background: var(--black-interaction-loading),
        color: var(--black-interaction-text-loading-color),
        --bouncing-ball-background: var(--black-interaction-text-loading-color),
        --btn-box-shadow: unset,
      ),
    ),
  // primery neon - yellow
  primary-neon:
    (
      default: (
        background: var(--yellow-interaction-standard),
        color: var(--yellow-interaction-text-color),
      ),
      hover: (
        background: var(--yellow-interaction-hover),
        color: var(--yellow-interaction-text-color),
      ),
      active: (
        background: var(--yellow-interaction-pressed),
        color: var(--yellow-interaction-text-color),
        --btn-box-shadow: var(--black-interaction-shadow-pressed),
      ),
      disabled: (
        background: var(--yellow-interaction-disabled),
        color: var(--yellow-interaction-text-disabled-color),
        --btn-box-shadow: unset,
      ),
      keyboard-focus: (
        background: var(--yellow-interaction-standard),
        color: var(--yellow-interaction-text-color),
        outline: 2px var(--neutral-9) solid,
        outline-offset: 2px,
      ),
      loading: (
        --bouncing-ball-background: var(--yellow-interaction-text-disabled-color),
      ),
    ),
  // secondary - outline
  secondary:
    (
      default: (
        background: var(--primary-variant-1),
        color: var(--grey-interaction-text-color),
        border: 1px solid var(--neutral-2),
      ),
      hover: (
        color: var(--grey-interaction-text-color),
        border: 1px solid var(--primary-variant-2),
      ),
      active: (
        color: var(--grey-interaction-text-pressed-color),
        border: 1px solid var(--neutral-2),
        --btn-box-shadow: var(--white-interaction-shadow-pressed),
      ),
      disabled: (
        color: var(--grey-interaction-text-disabled-color),
        border: 1px solid var(--grey-interaction-text-disabled-color),
        --btn-box-shadow: unset,
      ),
      keyboard-focus: (
        color: var(--grey-interaction-text-color),
        border: 1px solid var(--neutral-2),
        --btn-box-shadow: var(--white-interaction-shadow-keyboard-focus),
      ),
      loading: (),
    ),
  // secondary with grey bg
  secondary-grey:
    (
      default: (
        background: var(--grey-interaction-standard),
        color: var(--grey-interaction-text-color),
      ),
      hover: (
        background: var(--grey-interaction-hover),
        color: var(--grey-interaction-text-color),
      ),
      active: (
        background: var(--grey-interaction-pressed),
        color: var(--grey-interaction-text-pressed-color),
        --btn-box-shadow: var(--black-interaction-shadow-pressed),
      ),
      disabled: (
        background: var(--grey-interaction-disabled),
        color: var(--grey-interaction-text-disabled-color),
        --btn-box-shadow: unset,
      ),
      keyboard-focus: (
        background: var(--grey-interaction-standard),
        color: var(--grey-interaction-text-color),
        --btn-box-shadow: var(--black-interaction-shadow-keyboard-focus),
      ),
      loading: (
        background: var(--black-interaction-loading),
        color: var(--black-interaction-text-loading-color),
        --bouncing-ball-background: var(--black-interaction-text-loading-color),
        --btn-box-shadow: unset,
      ),
    ),
  tertiary: (
    default: (
      background: var(--blue-interaction-standard),
      color: var(--blue-interaction-text-color),
    ),
    hover: (
      background: var(--blue-interaction-hover),
      color: var(--blue-interaction-text-color),
    ),
    active: (
      background: var(--blue-interaction-pressed),
      color: var(--blue-interaction-text-color),
    ),
    disabled: (
      background: var(--blue-interaction-disabled),
      color: var(--blue-interaction-text-disabled-color),
    ),
    keyboard-focus: (),
    loading: (),
  ),
  // tertiary outline - border
  tertiary-outline:
    (
      default: (
        background: var(--primary-variant-1),
        color: var(--grey-interaction-text-color),
        border: 1px solid var(--primary-variant-2),
      ),
      hover: (
        background: var(--neutral-5),
        color: var(--grey-interaction-text-color),
      ),
      active: (
        background: var(--neutral-4),
        color: var(--neutral-1),
        border: 1px solid var(--primary-variant-2),
      ),
      disabled: (
        color: var(--grey-interaction-text-disabled-color),
        border: 1px solid var(--grey-interaction-text-disabled-color),
        --btn-box-shadow: unset,
      ),
      keyboard-focus: (
        background: var(--primary-variant-1),
        color: var(--grey-interaction-text-pressed-color),
        border: 1px solid var(--primary-variant-2),
        outline: 2px var(--neutral-1) solid,
        outline-offset: 2px,
      ),
      loading: (),
    ),
  // tertiary grey - border
  tertiary-grey:
    (
      default: (
        background-color: var(--neutral-6),
        color: var(--grey-interaction-text-color),
        border: 1px solid transparent,
        box-sizing: border-box,
      ),
      hover: (
        background: var(--neutral-5),
        color: var(--grey-interaction-text-color),
      ),
      active: (
        background: var(--neutral-4),
        color: var(--neutral-1),
        border: 1px solid var(--primary-variant-2),
      ),
      disabled: (
        color: var(--grey-interaction-text-disabled-color),
        border: 1px solid var(--grey-interaction-text-disabled-color),
        --btn-box-shadow: unset,
      ),
      keyboard-focus: (
        background: var(--primary-variant-1),
        color: var(--grey-interaction-text-pressed-color),
        border: 1px solid var(--primary-variant-2),
        outline: 2px var(--neutral-1) solid,
        outline-offset: 2px,
      ),
      loading: (),
    ),
  // link-tertiary
  link-tertiary:
    (
      default: (
        color: var(--neutral-1),
        min-width: unset,
        text-decoration: underline,
        text-underline-offset: 6px,
        text-decoration-thickness: 0.25px,
      ),
      hover: (
        color: var(--blue-interaction-standard),
      ),
      active: (
        color: var(--blue-interaction-pressed),
      ),
      disabled: (
        color: var(--black-interaction-text-disabled-color),
      ),
      keyboard-focus: (
        color: var(--neutral-1),
        --button-drop-shadow-focused: unset,
        outline: 2px var(--neutral-1) solid,
        outline-offset: var(--space-1),
      ),
      loading: (),
    ),
  // link-tertiary-light
  link-tertiary-light:
    (
      default: (
        color: var(--white),
        --btn-box-shadow: 0 0 0 0.25px var(--white),
        min-width: unset,
      ),
      hover: (
        color: var(--blue-interaction-standard),
        --btn-box-shadow: 0 0 0 0.25px var(--blue-interaction-standard),
      ),
      active: (
        color: var(--blue-interaction-pressed),
        --btn-box-shadow: 0 0 0 0.25px var(--blue-interaction-pressed),
      ),
      disabled: (
        color: var(--black-interaction-text-disabled-color),
        --btn-box-shadow: 0 0 0 0.25px var(--black-interaction-text-disabled-color),
      ),
      keyboard-focus: (
        color: var(--white),
        --btn-box-shadow: 0 0 0 0.25px var(--primary-variant-1),
        --button-drop-shadow-focused: unset,
        outline: 2px var(--primary-variant-1) solid,
        outline-offset: var(--space-1),
      ),
      loading: (),
    ),
  // upload
  upload:
    (
      default: (
        background: var(--white),
        border: 1px solid var(--field-interaction-standard),
        color: var(--field-interaction-placeholder-color),
        font-weight: var(--font-weight-regular),
        font-size: var(--label-text-sm),
      ),
      hover: (
        border: 1px solid var(--field-interaction-hover),
        color: var(--field-interaction-placeholder-color),
        box-shadow: $no-shadow,
      ),
      active: (
        border: 1px solid var(--field-interaction-standard),
        color: var(--field-interaction-placeholder-color),
      ),
      disabled: (
        background: var(--field-interaction-background-disabled-color),
        border: 1px solid var(--field-interaction-disabled),
        color: var(--field-interaction-placeholder-disabled-color),
      ),
      keyboard-focus: (),
      loading: (),
    ),
  // sia button
  sia:
    (
      default: (
        background-color: var(--sia-interaction-standard),
        color: var(--sia-interaction-text-color),
      ),
      hover: (
        background-color: var(--sia-interaction-hover),
        color: var(--sia-interaction-text-color),
      ),
      active: (
        background-color: var(--sia-interaction-pressed),
        box-sizing: border-box,
        color: var(--sia-interaction-text-pressed-color),
        --btn-box-shadow: var(--black-interaction-shadow-pressed),
      ),
      disabled: (
        background-color: var(--sia-interaction-disabled),
        color: var(--sia-interaction-text-disabled-color),
      ),
      keyboard-focus: (
        background-color: var(--sia-interaction-standard),
        color: var(--sia-interaction-text-color),
        outline: 2px var(--sia-interaction-standard) solid,
        outline-offset: 2px,
      ),
      loading: (),
    ),
  // transparent
  trasparent:
    (
      default: (
        background: transparent,
        color: var(--field-interaction-text-color),
      ),
      hover: (
        background: transparent,
        color: var(--field-interaction-text-color),
      ),
      active: (
        background: transparent,
        color: var(--field-interaction-text-color),
      ),
      disabled: (
        background: transparent,
        color: var(--grey-interaction-text-disabled-color),
      ),
      keyboard-focus: (),
      loading: (),
    ),
  grey-transparent: (
    default: (
      background: var(--neutral-6-opaque-80),
      color: var(--black-interaction-standard),
      box-shadow: var(--shadow-bottom-2),
      font-weight: var(--font-weight-regular),
    ),
    hover: (
      transform: translateY(0),
    ),
    active: (
      background: var(--neutral-6-opaque-80),
      color: var(--black-interaction-standard),
    ),
    disabled: (
      background: var(--neutral-6-opaque-80),
      color: var(--black-interaction-standard),
    ),
    keyboard-focus: (),
    loading: (),
  )
);

@keyframes bouncing-loader {
  to {
    opacity: 1;
    transform: translate3d(0, -6px, 0);
  }
}

.btn {
  --btn-box-shadow: unset;
  --btn-min-width: 108px;
  --btn-underline-v-spacing: 0;
  --btn-underline-h-spacing: 0;
  --btn-border-margin: initial;
  --button-drop-shadow-focused: 0 0 0 3px var(--primary-variant-1), 0 0 0 5px var(--neutral-1);

  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  appearance: none;
  user-select: none;
  vertical-align: middle;
  padding: var(--btn-v-padding) var(--btn-h-padding);
  gap: 6px;
  min-width: var(--btn-min-width);
  will-change: transform;
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;

  &::after {
    content: '';
    position: absolute;
    top: var(--btn-underline-v-spacing);
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: var(--btn-box-shadow);
    border-radius: inherit;
    margin: var(--btn-border-margin);
  }

  &.icon-right::after {
    right: var(--btn-underline-h-spacing);
  }
  &.icon-left::after {
    left: var(--btn-underline-h-spacing);
  }
  &.is-icon-btn {
    min-width: unset;
    &::after {
      --btn-underline-h-spacing: 0;
    }
  }

  @each $variant, $content in $button-variants {
    &.btn-#{$variant} {
      @include spread-props(map-get-strict($content, 'default'));

      &:hover:not(:disabled):not(:active) {
        transform: translateY(-2px);
        @include spread-props(map-get-strict($content, 'hover'));

        &[class*='link-'] {
          transform: translateY(0);
          &::after {
            --btn-underline-v-spacing: 100%;
          }
        }
      }

      // Reset to default state on mobile device after tap
      @media (-moz-touch-enabled: 1), (pointer: coarse) {
        &:hover:not(:disabled):not(:active) {
          @include spread-props(map-get-strict($content, 'default'));
        }
      }

      // TODO: Sachin - Check with team on removing focus??
      // &:focus,
      &:active {
        @include spread-props(map-get-strict($content, 'active'));
      }
      &:disabled {
        cursor: not-allowed;
        @include spread-props(map-get-strict($content, 'disabled'));
      }
      &:focus-visible {
        @include spread-props(map-get-strict($content, 'keyboard-focus'));
      }
      &.loading {
        @include spread-props(map-get-strict($content, 'loading'));

        > div {
          width: 8px;
          height: 8px;
          background: var(--bouncing-ball-background);
          border-radius: 50%;
          animation: bouncing-loader 0.6s infinite alternate;

          &:nth-child(1) {
            margin-left: var(--space-2);
          }
          &:nth-child(2) {
            animation-delay: 0.2s;
          }
          &:nth-child(3) {
            animation-delay: 0.4s;
          }
        }
      }
    }
  }

  &.btn-small {
    --btn-v-padding: var(--space-1);
    --btn-h-padding: var(--space-4);
    --btn-min-width: 63px;
    --btn-underline-h-spacing: 24px;

    @extend .label-sm;
    border-radius: var(--border-radius-sm);
    line-height: 20px;

    &.btn-circular {
      --btn-v-padding: var(--space-1);
      --btn-h-padding: var(--space-1);
    }
  }

  &.btn-medium {
    --btn-v-padding: var(--space-2);
    --btn-h-padding: var(--space-4);
    --btn-min-width: 90px;
    --btn-underline-h-spacing: 28px;

    @extend .label-md;
    border-radius: var(--border-radius-sm);
    line-height: 24px;

    &.btn-circular {
      --btn-v-padding: calc(var(--space-2) - 2px);
      --btn-h-padding: calc(var(--space-2) - 2px);
    }
  }

  &.btn-semi-large {
    --btn-v-padding: calc(var(--space-2) + 2px);
    --btn-h-padding: var(--space-4);
    --btn-min-width: 90px;
    --btn-underline-h-spacing: 28px;

    @extend .label-md;
    border-radius: var(--border-radius-sm);
    line-height: 24px;

    &.btn-circular {
      --btn-v-padding: calc(var(--space-2) - 2px);
      --btn-h-padding: calc(var(--space-2) - 2px);
    }
  }

  &.btn-large,
  &.btn-x-large {
    --btn-v-padding: var(--space-3);
    --btn-h-padding: var(--space-4);
    --btn-min-width: 108px;
    --btn-underline-h-spacing: 32px;

    @extend .label-lg;
    border-radius: var(--border-radius-md);
    line-height: 24px;

    &.btn-circular {
      --btn-v-padding: var(--space-2);
      --btn-h-padding: var(--space-2);
    }
  }

  &.btn-x-large {
    &.btn-circular {
      --btn-v-padding: calc(var(--space-2) + 2px);
      --btn-h-padding: calc(var(--space-2) + 2px);
    }
  }

  &[class*='link-'] {
    --btn-v-padding: 0;
    --btn-h-padding: 0;
    --btn-underline-v-spacing: 100%;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:focus-visible::before {
      box-shadow: var(--button-drop-shadow-focused);
      border-radius: var(--border-radius-xsm);
    }
    &.has-icon {
      text-decoration: none;
      &:hover:not(:disabled),
      &:active,
      &:focus {
        text-decoration: none;
      }

      &::after {
        content: unset;
      }
    }
  }

  &.btn-circular {
    --btn-h-padding: var(--btn-v-padding);
    border-radius: var(--border-radius-xl);

    &:hover:not(:disabled):not(:active):not(:focus) {
      transform: translateY(0);
    }
  }

  &.btn-rounded {
    border-radius: var(--border-radius-xl);
  }

  &.btn-fluid {
    width: 100%;
  }
}
