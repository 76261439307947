@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

:global(.notification) + .header {
  top: var(--notification-height);
}

@mixin background-transition {
  transition: background 200ms ease-in-out;
}

@mixin nav-item-bottom-border-highlight {
  &::after {
    content: '';
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.headerContainer {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  z-index: var(--header-nav-zindex);

  &.nonSticky {
    position: relative;
  }
}

.new {
  @include text-xs-bold;

  color: var(--info);
  text-transform: uppercase;
  margin-left: var(--space-1);
  transform: translateY(-2px);
}

.header {
  --header-bottom-position: var(--header-height);

  z-index: var(--header-nav-zindex);
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--header-height);
  background: var(--header-nav-bg);
  box-shadow: var(--shadow-bottom-2);
  width: 100vw; // to make sure custom scroll width is not consuming space from header
  @include background-transition;

  @mixin transparentEffect($textColor, $bgColor) {
    box-shadow: none;
    --header-nav-text-color: #{$textColor};
    --header-nav-bg: #{$bgColor};
  }

  & + .mobileMenu {
    @include until-phone {
      position: fixed;
      @include transparentEffect(var(--text-color), 'inherit');
    }
  }

  &.transparent {
    &.home {
      @include transparentEffect(var(--primary-variant-1), 'transparent');
    }
  }

  @include until-phone {
    padding: 0 var(--page-side-space);

    .headerNavItem:last-child {
      margin-right: calc(var(--page-side-space) * -1);
    }
  }

  @include until-wide-screen {
    .headerButtonLabel {
      display: none;
    }
  }

  &Title {
    color: white;
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
    padding-left: var(--space-4);
    margin-left: var(--space-4);
    border-left: 1px solid white;
    align-self: center;
  }

  &Inner {
    @include maxContentWidth;
    display: flex;
  }

  &NavLeft {
    display: flex;
    margin-right: var(--space-20);

    @include mediaMaxWidth(1360px) {
      margin-right: var(--space-10);
    }

    &.isBlog {
      margin-right: var(--space-4);
    }

    &.fullWidth {
      flex: 1 1;
    }
  }

  &NavCenter {
    flex-grow: 2;

    form {
      width: 100%;
    }
  }

  &NavRight {
    display: flex;
    align-items: center;
  }

  &Logo {
    padding: 0;
    cursor: pointer;

    img {
      object-fit: contain;
    }
  }

  &NavItem {
    display: flex;
    align-items: center;
    color: var(--header-nav-text-color);
    font-weight: 600;
    position: relative;
    @include background-transition;

    &:not(.headerLogo) {
      @include from-phone {
        &:not(._noHover):hover,
        &.active {
          @include nav-item-bottom-border-highlight;
        }
      }

      // until 480px
      @include until-phone {
        padding: 0 var(--space-4);

        &.hasSearch,
        &.isBlog {
          padding: 0;
          width: 100%;

          > form {
            width: 100%;
          }
        }
      }

      // until 374px
      @include mediaMaxWidth(374px) {
        padding: 0 var(--space-2);

        &.hasSearch,
        &.isBlog {
          padding: 0;
          width: 100%;
        }
      }

      svg {
        display: block;
      }
    }
  }

  .isPartner {
    display: flex;
    width: 93px;
    height: 40px;
    border-left: 0.5px solid var(--white);
    padding-left: var(--space-4);
    margin-right: var(--space-20);

    @include until-tablet {
      margin-right: var(--space-10);
    }

    @include until-phone {
      margin-left: var(--space-4);
      margin-right: 0;

      &.hide {
        display: none;
      }
    }
  }

  .blogNavContainer {
    align-self: center;
    margin-right: 114px; // this is exceptional space for blog - not in multiple of 4

    @include until-phone {
      margin-left: var(--space-4);
      margin-right: 0;
    }

    .blogNav {
      padding: var(--space-1) var(--space-2);
      border: 1px solid var(--primary-variant-1);
      border-radius: var(--border-radius-sm);
      color: var(--primary-variant-1);
      background-color: transparent;
      cursor: pointer;
      text-transform: uppercase;

      @include text-sm-bold;
    }
  }
}

.transparentBtn {
  background-color: transparent !important;
}

.helpcenterIcon {
  padding-right: var(--space-1) !important;
  padding-left: var(--space-0);
  margin-left: var(--space-0);
  svg {
    width: 24px;
    height: 24px;
  }
}

.searchDropdownCard {
  width: 100%;
}

.helpCenterNavItem {
  &.active {
    overflow: visible;
    @include nav-item-bottom-border-highlight;
  }
}

.userName {
  @include label-md-bold;
  font-weight: var(--font-weight-extra-bold);
  color: var(--text-color);
}

.avatar {
  height: 32px;
  text-transform: uppercase;
  width: 32px;
  background: var(--primary);
  color: var(--text-color);
  @include label-sm-bold;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-xl);

  svg {
    margin-right: 0;
  }
}

.closeIcon {
  display: flex;
  align-items: center;
  color: var(--header-nav-text-color);
  flex-direction: column;
  justify-content: center;
  margin-left: var(--space-2);

  svg {
    margin: 0;
    color: var(--header-nav-text-color);
  }
}

.accountMenu {
  min-width: 170px;

  &.wide {
    min-width: 220px;
  }
}

.signupDropdownButton {
  padding-top: var(--space-2) !important;
}

.signupDropdown {
  width: 275px;
  padding: 0 !important;
  cursor: pointer;

  .signupDropdownContent {
    background-color: var(--neutral-7);
    padding: var(--space-4);
    border-bottom-left-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);

    display: flex;
    align-items: flex-end;
    gap: var(--space-2);

    @include label-sm-bold;
    font-weight: var(--font-weight-semi-bold);
    font-family: var(--font-family-montserrat);
    color: var(--text-color-grey-1);

    svg {
      max-width: 44px;
      width: 100%;
    }
  }
}

.currencyContent {
  padding: 0 !important;

  @include until-tablet {
    transform: translateX(25%);
  }
}

.helpContent {
  min-width: 232px;
}

.dropdownContent {
  padding: var(--space-3) 0;
  border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  background-color: var(--white);
  box-shadow: var(--shadow-bottom-2);

  &.destinationSwitcherContent {
    padding: 0 !important;
    color: var(--text-color);
    overflow: hidden;
  }
}

.mobileMenu {
  --padding-x: var(--space-6);

  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--sizebar-zindex);
  transform: translateY(-100%);
  transition: transform 0.2s, opacity 0.2s ease-in-out;
  will-change: transform;
  @include size(100%);
  opacity: 0;

  &.active {
    top: var(--header-bottom-position);
    overflow: auto;
    height: calc(100% - (var(--header-bottom-position)));
    transform: translateY(0);
    opacity: 1;
    z-index: calc(var(--header-nav-zindex) - 1);

    .mobileMenuItems.menuActive {
      box-shadow: var(--shadow-bottom-4);
    }
  }

  .mobileMenuItems {
    will-change: transform;
    transition: transform 0.2s ease-out;
    @include absFullSize;
  }

  .mobileMenuItems.menuActive {
    transform: translateX(-100%);

    .dropdownContent:after {
      // overlay block to close currency menu onClick
      content: '';
      display: block;
      @include absFullSize;
    }
  }

  .dropdownContent {
    --setting-item-padding-v: var(--space-3);
    --setting-item-padding-h: 0;
    --icon-size: 24px;

    padding: var(--space-4) var(--padding-x);
    box-shadow: none;
    overflow: scroll;
    height: 100%;

    &.withSignupBanner {
      // Adjust height of the Signin/Signup banner
      height: calc(100% - 120px);
    }
  }
}

.currency {
  &List {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(var(--items-per-row), min-content);
    grid-auto-flow: column;
    overflow: auto;

    @include until-phone {
      --scrollbar-width: 4px;

      grid-template-columns: 1fr;
      grid-auto-flow: row;
      max-height: calc(
        100vh - var(--notification-height) - var(--header-height) - var(--currency-menu-header-height) - 36px
          /* add 36px so that last item can be readable */
      );
    }
  }

  &Item {
    color: var(--text-color-grey-2);
    line-height: 1.33;
    white-space: nowrap;
    cursor: pointer;

    &.selected {
      color: var(--text-color);
      font-weight: bold;
    }
  }

  &Label {
    display: inline-block;
    margin-right: var(--space-4);
    min-width: 35px;
  }
}

.language,
.currency {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .value {
    color: var(--neutral-1);
    display: flex;
    align-items: center;
    @include label-sm;

    svg {
      margin-left: var(--space-2);
      margin-right: 0;
      color: var(--text-color-grey-2) !important;
    }
  }
}

.wishlistNavItem {
  color: var(--header-nav-text-color);
  text-decoration: none;
  position: relative;

  &.active {
    @include nav-item-bottom-border-highlight;
  }
}

.wishlistNavItem .badge {
  position: absolute;
  top: 12px;
  left: 42px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--text-color);
  font-size: var(--text-xs);
  font-weight: var(--font-weight-bold);
  line-height: 14px;

  @include until-phone {
    left: 32px;
  }
}

.wishlistCta {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--header-nav-text-color);

  &.hasItems {
    margin-left: calc(var(--space-1) / 2);
  }
}

.hasMiniLogo {
  margin-right: var(--space-4);
}

.languages {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: var(--space-6);
  flex-direction: column;
}
