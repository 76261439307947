@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.card {
  --hover-icon-space: 24px;

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--space-2) var(--space-4);
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--neutral-6);
    outline: none;

    .body .hoverIcon {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @include until-phone {
    &:hover {
      background: none;
    }
  }

  .product {
    position: relative;
  }

  .productImage {
    object-fit: cover;
    border-radius: var(--border-radius-md);
    width: 100%;
    max-width: 40px;
  }

  .destination,
  .product {
    object-fit: cover;
    border-radius: var(--border-radius-md);
    width: 100%;
    max-width: 40px;
    height: 40px;
  }

  .destination {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--neutral-6);
    color: var(--text-color-grey-2);
  }

  .body {
    width: 100%;
    overflow-x: auto;
    margin-left: var(--space-3);
    display: grid;
    grid-template-columns: auto 24px;
    grid-gap: var(--space-3);
    align-items: center;

    .content {
      overflow: hidden;
    }

    // similar style is also available in info-header
    .hoverIcon {
      color: var(--text-color-grey-1) !important;
      opacity: 0;
      transform: translateX(-16px);
      transition: opacity 180ms ease-in-out, transform 200ms ease-in-out;
    }

    .title {
      @include overflowedText;
      @include label-md;
      text-align: left;
      color: var(--text-color-grey-1);
      font-weight: var(--font-weight-regular);

      @include until-phone {
        @include overflowedText(2, true);
      }
    }

    .description {
      margin-top: 2px;
      @include text-sm;
      font-weight: var(--font-weight-regular);
      color: var(--text-color-grey-2);
    }
  }
}

.destinationCard {
  padding: var(--space-1) var(--space-4);
}
